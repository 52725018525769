<template>
  <div class="modal-season-select" v-if="booleanIsTournamentsModalOpen">
    <div class="modal-season-select__content">
      <div class="modal-season-select__content__separator">
        <div class="separator"></div>
      </div>
      <div class="modal-season-select__content__controls">
        <div class="modal-season-select__content__controls__select">
          <div class="modal-season-select__content__controls__select__title">
            Torneo:
          </div>
          <BSelect :value="objectTournamentSelected" placeholder="Seleccione" expanded @input="changeTournament">
            <option v-for="objectTournament in arrayTournaments" :key="objectTournament.id" :value="objectTournament">
              {{ objectTournament.name }}
            </option>
          </BSelect>
        </div>
        <div class="modal-season-select__content__controls__select">
          <div class="modal-season-select__content__controls__select__title">
            Temporada:
          </div>
          <BField position="is-centered" type="is-info" class="select-statistics">
            <BSelect :value="objectSeasonSelected" placeholder="Seleccione" expanded @input="changeSeason">
              <option
                v-for="objectSeason in objectTournamentSelected.seasons"
                :key="objectSeason.id"
                :value="objectSeason"
              >
                {{ objectSeason.name }}
              </option>
            </BSelect>
          </BField>
        </div>
        <div class="modal-season-select__content__controls__button-ok">
          <div role="button" class="button" @click="onClickOk">
            OK
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTeamsBySeasonV2 } from '@/utils/api/teams';
import { mapMutations, mapState } from 'vuex';
import axios from 'axios';

export default {
  name: 'ModalSeasonSelect',
  components: {
    BSelect: async () => {
      const { BSelect } = await import('buefy/dist/esm/select');
      return BSelect;
    },
  },
  data() {
    return {
      htmlComponent: null,
    };
  },
  computed: {
    ...mapState('leagueGeneral', [
      'arrayTournaments',
      'objectTournamentSelected',
      'objectSeasonSelected',
      'booleanIsTournamentsModalOpen',
    ]),
  },
  watch: {
    booleanIsTournamentsModalOpen(newValue) {
      if (newValue) {
        if (!this.htmlComponent) {
          this.htmlComponent = document.getElementsByTagName('html')[0];
        }
        this.htmlComponent.style.overflow = 'hidden';
      } else {
        if (this.htmlComponent) {
          this.htmlComponent.style.overflow = 'auto';
        }
      }
    },
    'objectSeasonSelected.id': {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.fillLeagueSeasonTeams(newValue);
        }
      },
    },
  },
  methods: {
    ...mapMutations('leagueGeneral', [
      'setArrayTournaments',
      'setObjectTournamentSelected',
      'setObjectSeasonSelected',
      'setObjectSeasonsByRegularSeason',
      'setBooleanIsTournamentsModalOpen',
      'setArrayTeamsBySeason',
    ]),
    async fillLeagueSeasonTeams(numberSeasonId) {
      try {
        const response = await getTeamsBySeasonV2({ seasonId: numberSeasonId });
        const arrayTeamsBySeason = response.data.teams || [];
        this.setArrayTeamsBySeason(arrayTeamsBySeason);
      } catch (e) {
        throw new Error(e);
      }
    },
    onClickOk() {
      this.setBooleanIsTournamentsModalOpen(false);
    },
    changeTournament(objectTournament) {
      this.setObjectTournamentSelected(objectTournament);
      this.changeSeason(objectTournament.seasons[0]);
      // if (val.id === 33) {
      //   const nowMillis = new Date().getTime();
      //   const qatarEnabledDayMillis = new Date('2022-11-21T03:59:00.000Z').getTime();
      //   this.storeTournamentSelected(val);
      //   this.storeTournamentNameSelected(this.getTournaments[val].name);
      //   this.storeSeasonNameSelected(
      //     this.getSeasons[val][Object.keys(this.getSeasons[val])[nowMillis >= qatarEnabledDayMillis ? 0 : 1]].name,
      //   );
      //   this.storeSeasonSelected(
      //     Object.keys(this.getSeasons[val])[nowMillis >= qatarEnabledDayMillis ? 0 : 1].slice(1),
      //   );
      // } else {
      //   this.storeTournamentSelected(val);
      //   this.storeTournamentNameSelected(this.getTournaments[val].name);
      //   this.storeSeasonNameSelected(this.getSeasons[val][Object.keys(this.getSeasons[val])[0]].name);
      //   this.storeSeasonSelected(Object.keys(this.getSeasons[val])[0].slice(1));
      // }
    },
    changeSeason(objectSeason) {
      this.setObjectSeasonSelected(objectSeason);
    },
    async fillTournaments() {
      try {
        let arrayTournaments = await this.fetchTournaments();
        arrayTournaments = arrayTournaments.map(objectTournamentContainer => {
          return {
            ...objectTournamentContainer.tournament,
            seasons: objectTournamentContainer.seasons,
          };
        });
        const objectMainLeague =
          arrayTournaments.find(objectTournament => {
            return objectTournament.id === 1;
          }) || {};
        const objectSeasonsByRegularSeason = this.getSeasonsByRegularSeason(arrayTournaments);
        this.setArrayTournaments(arrayTournaments);
        this.setObjectTournamentSelected(objectMainLeague || {});
        this.setObjectSeasonSelected(objectMainLeague ? objectMainLeague.seasons[0] || {} : {});
        this.setObjectSeasonsByRegularSeason(objectSeasonsByRegularSeason);
      } catch (e) {
        throw new Error(e);
      }
    },
    async fetchTournaments(retries = 3) {
      try {
        const response = await axios.get('https://17zyhpyc7c.execute-api.us-west-2.amazonaws.com/prod/tournaments');
        // const response = await axios.get('https://25d2d6f5ad.execute-api.us-west-2.amazonaws.com/qa/tournaments');
        return response.data || [];
      } catch (e) {
        if (retries > 0) {
          return this.fetchTournaments(retries - 1);
        } else {
          return Promise.reject(e);
        }
      }
    },
    getSeasonsByRegularSeason(tournamentsAndSeasons) {
      return tournamentsAndSeasons.reduce((groupedSeasons, tournamentAndSeasons) => {
        const regularSeason = tournamentAndSeasons.seasons[0];
        groupedSeasons[regularSeason.id] = [
          { id: regularSeason.id, type: regularSeason.type, name: regularSeason.name },
        ];
        if (regularSeason.related_seasons && regularSeason.related_seasons.length) {
          groupedSeasons[regularSeason.id].push(
            ...regularSeason.related_seasons.map(relatedSeason => ({
              id: relatedSeason.id,
              type: relatedSeason.type,
            })),
          );
        }
        return groupedSeasons;
      }, {});
    },
  },
  created() {
    this.fillTournaments();
  },
};
</script>

<style scoped lang="scss">
.modal-season-select {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 60px;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 15;
  display: flex;
  justify-content: center;

  &__content {
    width: 100%;
    max-width: 1270px;
    display: grid;
    grid-template-columns: 1fr max-content;
    column-gap: 1em;

    &__separator {
      width: 100%;
      padding-top: 2.5em;

      & > div.separator {
        width: 100%;
        border-bottom: 1px dashed #aaaaaa;
      }
    }

    &__controls {
      display: flex;

      & > div {
        margin: 0 8px;
      }

      &__select {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &__title {
          font-family: Roboto-Regular, sans-serif;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.31;
          letter-spacing: -0.13px;
          text-align: center;
          color: #3c444b;
        }
      }

      &__button-ok {
        padding-top: 1em;
        & > .button {
          border-radius: 50px;
          background-color: #428ee6;
          min-width: 88px;
          color: white;
          font-family: Heebo, sans-serif;
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.44;
          letter-spacing: normal;
          text-align: left;
          cursor: pointer;
        }
      }
    }
  }

  @media screen and (max-width: 524px) {
    &__content {
      grid-template-columns: 1fr;
      column-gap: 0;

      &__controls {
        justify-content: space-between;

        &__button-ok {
          & > .button {
            min-width: fit-content;
            padding: 0 1em;
          }
        }
      }
    }
  }

  @media screen and (max-width: 566px) {
    padding-top: 4.5em;

    &__content {
      grid-template-columns: 1fr;
      column-gap: 0;

      &__controls {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 8px;
        &__button-ok {
          display: none;
        }
      }

      &__separator {
        display: none;
      }
    }
  }
}
</style>

<style lang="scss">
.modal-season-select {
  & .modal-season-select__content__controls {
    & select {
      font-size: 17px;
      font-family: Roboto-Medium, sans-serif !important;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: -0.17px;
      color: #132839;
    }
  }
}
</style>
